.flowchart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .node {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .arrow {
    font-weight: bold;
    margin: 0 10px;
  }
  
  .text {
    font-weight: bold;
  }
  
  /* Additional styling for the Chip component */
  