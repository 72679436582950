.react-calendar-heatmap .color-scale-0 {
  fill: #eB6272;
}
.react-calendar-heatmap .color-scale-100 {
  fill: #65D193;
}

.react-calendar-heatmap {
  max-height: 600px;
  width: 100%;
}

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

.react-calendar-heatmap-weekday-labels {
  transform: translate(8px, 12px);
}
