/* This CSS can be applied to 
<Typography/> or vanilla HTLM elements 
to give the look of a hyperlink or <a></a> tag
*/
html {
  scroll-behavior: smooth;
}

.text-links {
  color: #0071b1;
  cursor: pointer;
}

.text-links:hover {
  text-decoration: underline;
}

