.container {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.05);
    border: 1px solid #ccc;
    overflow: scroll;
    transition: border-color 0.3s;
    padding: 1px; /* Added padding */
    max-height: 300px;
    overflow-x: hidden; /* Added this line */
}

.container:hover {
    border-color: #6779E1;
}

.row {
    display: flex; /* Display options in one line per row */
    flex-wrap: wrap; /* Wrap options to the next line if needed */
    gap: 10px; /* Add spacing between options */
    width: 100%; /* Ensure the row takes up the full width */
}

.option {
    flex: 1; /* Allow options to grow and shrink equally within the row */
    margin-bottom: 10px; /* Add some space between rows */
    border-radius: 5px;
    background: none;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    transition: background 0.3s;
    outline: none;
    /* Remove overflow and text-overflow properties */
    white-space: normal; /* Allow options to wrap into multiple lines */
    word-wrap: break-word; /* Break words if they don't fit */
    padding: 5px; /* Add some padding for better appearance */
}

.option:hover {
    background: #42a5f5;
    color: #fff;
}