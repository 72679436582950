#evidence-styles {
    max-height: 180px;
    overflow: auto;
    font-size: 9pt;
    text-align:left;
    background-color: #FCF7EC;
    overflow-x: auto; /* Use horizontal scroller if needed; for Firefox 2, not
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: pre-wrap;
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    margin: 0px 0px 0px 0px;
    padding:5px 5px 3px 5px;
}