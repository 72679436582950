/* Dark mode styles */
.container {
    border-radius: 10px;
    background: #56545c; /* Dark background */
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
    border: 1px solid #56545c; /* Dark border color */
    overflow: scroll;
    overflow-x: hidden;
    transition: border-color 0.3s, background-color 0.3s;
    padding: 1px;
    height: 200px;
    color: #ddd; /* Light text color */
}

.container:hover {
    border-color: #a5a5f5; /* Slightly lighter border on hover */
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    width: 100%;
}

.option {
    flex: 1;
    margin-bottom: 10px;
    border-radius: 5px;
    background: none;
    color: #ddd; /* Light text color for readability */
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    white-space: normal;
    word-wrap: break-word;
    padding: 5px;
}

.option:hover {
    background: #5c9edf; /* Dark mode hover background */
    color: #fff; /* White text on hover */
}

/* Input inside .option with matching background color for "invisible" text */
.container input {
    color: #fff; /* Matches background color for "invisible" typing */
    background: #56545c; /* Dark background */
    border: none;
    outline: none;
}
